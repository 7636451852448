<template>
  <v-app>
    <!--SP/TB版ヘッダー・ハンバーガーメニュー-->
    <div v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <HeaderSp />
    </div>

    <!--PC版ヘッダー-->
    <div v-else>
      <HeaderPc />
    </div>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import HeaderSp from './components/naviComponent/HeaderSp.vue'
import HeaderPc from './components/naviComponent/HeaderPc.vue'
export default {
  name: 'App',
  components: {
    HeaderSp : HeaderSp,
    HeaderPc : HeaderPc,
  },
  data () {
    return {
    }
  }
};
</script>
