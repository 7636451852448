<template>
    <header id="navi_sp">
        <v-app-bar app color="black" dark style="padding: 0;">
            <div class="d-flex align-center">
            <span>ELPLANT</span>
            </div>
            <v-spacer></v-spacer>
            <!--ハンバーガーメニューボタン-->
            <div @click="drawer = true">
            <v-icon>mdi-menu</v-icon>
            </div>
        </v-app-bar>

        <!--ハンバーガーメニュー中身-->
        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            style="background-color: black;"
            dark
            right
        >
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item>
                        <router-link :to="{name:'home'}">
                            <v-list-item-title>ホーム</v-list-item-title>
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link :to="{name:'about'}">
                            <v-list-item-title>会社概要</v-list-item-title>
                        </router-link>
                    </v-list-item>
                    <!--
                    <v-list-item>
                        <router-link :to="{name:'game'}">
                            <v-list-item-title>ゲーム事業</v-list-item-title>
                        </router-link>
                    </v-list-item>
                    -->
                    <v-list-item>
                        <router-link :to="{name:'matching'}">
                            <v-list-item-title>レッツミー</v-list-item-title>
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link :to="{name:'information'}">
                            <v-list-item-title>お知らせ</v-list-item-title>
                        </router-link>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </header>
</template>

<script>

export default {
    name: 'HeaderSp',
    data () {
        return {
        drawer: false
        }
    }
};
</script>

<style scoped>
#navi_sp a {
    text-decoration: none;
    color: white;
}
</style>