<template>
    <div fluid class="game">
        <!--SPトップ画像-->
        <v-img
            v-if="$vuetify.breakpoint.xs"
            src="@/assets/game.jpeg"
            aspect-ratio="1"
        ></v-img>
        <!--PCトップ画像-->
        <v-img
            v-else
            src="@/assets/game.jpeg"
        ></v-img>
        <v-container fluid>
            <p class="text-center mt-5" style="font-size: 0.8rem;">現在開発中</p>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'Game',
        components: {
        },
    }
</script>