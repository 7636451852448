<template>
  <!--ホーム画面-->
  <div>
    <!--SP版-->
    <div v-if="$vuetify.breakpoint.xs">
      <!--トップ背景画像-->
      <v-img
        src="@/assets/tree.jpeg"
        aspect-ratio="1"
      >
      <div style="padding-top: 40%; color: white;" class="text-center">
          <h1>未来を創る、好奇心。</h1>
          <p>curiosity to create the future</p>
        </div>
      </v-img>
    </div>

    <!--PC版-->
    <div v-else>
      <!--トップ背景画像・メニュー-->
      <v-img
        src="@/assets/tree.jpeg"
      >
        <div style="padding-top: 20%; color: white;" class="text-center">
          <h1>未来を創る、好奇心。</h1>
          <p>curiosity to create the future</p>
        </div>
      </v-img>
    </div>

    <!--共通-->
    <div style="padding-top: 1rem; margin-bottom: 5rem;">
      <v-row align="center" justify="center" style="padding-right: 0; padding-left: 0; margin-right: 0; margin-left: 0;">
        <v-col cols="11" xs="11" sm="10" md="10" lg="10" xl="8" class="pa-md-4 mx-lg-auto" style="padding-left: 0; padding-right: 0; padding-top: 0;">
          <p style="font-size: 0.8rem; margin-top: 1rem;">ニュース</p>
          <p style="font-size: 0.6rem; color: grey; margin-bottom: 0;">2022年12月13日</p>
          <h5 style="margin-bottom: 0.8rem;">警視庁インターネット異性紹介事業者に登録</h5>
          <p style="font-size: 0.6rem; color: grey; margin-bottom: 0;">2022年11月1日</p>
          <h5 style="margin-bottom: 0.8rem;">Twitterアカウントを開設</h5>
          <p style="font-size: 0.6rem; color: grey; margin-bottom: 0;">2022年11月1日</p>
          <h5>ELPLANT株式会社が創業</h5>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    components: {
    },
  }
</script>
