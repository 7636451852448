<template>
    <div>
        <!--SPトップ画像---->
        <v-img
            v-if="$vuetify.breakpoint.xs"
            src="@/assets/banner1.jpg"
        >
        </v-img>
        <p v-if="$vuetify.breakpoint.xs" style="font-size: 0.6rem; margin-bottom: 0;">※18歳未満または高校生の方はご利用頂けません</p>
        <!--PCトップ画像---->
        <v-img
            v-else
            src="@/assets/elplant_matching_pc.jpg"
        >
            <div style="padding-top: 35%; color: white;" class="text-center">
                <p style="font-size: 0.6rem; margin-bottom: 0;">※18歳未満または高校生の方はご利用頂けません</p>
                <!--
                <h1>出会いをもっと便利に、もっと楽しく。</h1>
                <h4>皆の"欲しい"から、次世代のマッチングアプリが誕生しました。</h4>
                -->
                <h1>新たな出会いをもっと便利に、もっと楽しく。</h1>
                <h4>皆の"欲しい"から、新しいアプリが誕生しました。</h4>
            </div>
        </v-img>
        <!--SP版サービス紹介-->
        <v-container v-if="$vuetify.breakpoint.xs" class="matching" style="padding-top: 2rem;">
            <!--紹介1
            <h4 style="color: grey; margin-bottom: 1rem;">必要なのは時間、場所、やりたい事だけ。</h4>
            <v-row align="center" justify="center" style="margin-bottom: 1rem;">
                <v-col cols="3">
                    <v-img src="@/assets/sample_mock2.jpg"></v-img>
                </v-col>
                <v-col cols="8">
                    <p style="font-size: 0.6rem; color: grey;">
                        マッチングアプリ疲れが叫ばれる昨今、マッチした後のやり取りが続かなかったり、いつまで経っても実際に会えないと悩んでいませんか。
                        本サービスではいつ、どこで、何をしたいかを決めた上でマッチングします。
                    </p>
                    <p style="font-size: 0.6rem; color: grey;">
                        マッチング後はメッセージを送って細かい調整をするだけだから簡単、そしてタイムパフォーマンスに優れています。美味しいご飯を食べに行ったり、一緒にスポーツ観戦したり、自分が最も理想とする出会いの形をセッティング頂けます。
                    </p>
                </v-col>
            </v-row>
            -->
            <h4 style="color: grey; margin-bottom: 1rem;">必要なのは時間、場所、やりたい事だけ。</h4>
            <v-row align="center" justify="center" style="margin-bottom: 1rem;">
                <v-col cols="3">
                    <v-img src="@/assets/sample_mock2.jpg"></v-img>
                </v-col>
                <v-col cols="8">
                    <p style="font-size: 0.6rem; color: grey;">
                        レッツミーは様々な方法で新しい繋がりを生み出すサービスです。時間・場所・目的を共有するからお互いが打ち解けるまでとても早く、一生ものの友達ができることでしょう。
                    </p>
                </v-col>
            </v-row>

            <!--紹介2
            <h4 style="color: grey; margin-bottom: 1rem; padding-top: 2rem;">ひとりでも友達と一緒でも。最大16人で合コン。</h4>
            <v-row align="center" justify="center" style="margin-bottom: 1rem;">
                <v-col cols="8">
                    <p style="font-size: 0.6rem; color: grey;">
                        本サービスの最大の特徴。それは何と言っても複数人でのマッチング機能です。おひとり様同士のマッチングは勿論、幹事が中心となってグループ同士でマッチングする事が可能です。
                    </p>
                    <p style="font-size: 0.6rem; color: grey;">
                        友達と一緒だから安心安全。友達と一緒だから盛り上がる。あなたも身近な人を誘って素敵な出会いを楽しみませんか？
                    </p>
                </v-col>
                <v-col cols="3">
                    <v-img src="@/assets/sample_mock1.jpg"></v-img>
                </v-col>
            </v-row>
            -->

            <h4 style="color: grey; margin-bottom: 1rem; padding-top: 2rem;">ひとりでも友達と一緒でも。最大16人で。</h4>
            <v-row align="center" justify="center" style="margin-bottom: 1rem;">
                <v-col cols="8">
                    <p style="font-size: 0.6rem; color: grey;">
                        本サービスの最大の特徴。それは何と言っても複数人で利用できる点です。おひとり様同士は勿論、幹事が中心となってグループ同士で合流する事が可能です。
                    </p>
                    <p style="font-size: 0.6rem; color: grey;">
                        友達と一緒だから安心安全。友達と一緒だから盛り上がる。あなたも身近な人を誘ってさらなる繋がりを作りませんか？
                    </p>
                </v-col>
                <v-col cols="3">
                    <v-img src="@/assets/sample_mock1.jpg"></v-img>
                </v-col>
            </v-row>

            <!--紹介3
            <h4 style="color: grey; margin-bottom: 1rem; padding-top: 2rem;">位置情報を使って今すぐ出会える。</h4>
            <v-row align="center" justify="center" style="margin-bottom: 1rem;">
                <v-col cols="3">
                    <v-img src="@/assets/sample_mock3.jpg"></v-img>
                </v-col>
                <v-col cols="8">
                    <p style="font-size: 0.6rem; color: grey;">
                        前もって予定を立てる通常のマッチング機能の他に、本サービスでは位置情報を使って半径10km圏内で今すぐ出会えるお相手を探す機能をご用意しました。急に予定が空いた時、暇で仕方ない時など、どんな時でも24時間お相手をお探し頂けます。
                    </p>
                    <p style="font-size: 0.6rem; color: grey;">
                        位置情報を使うことに抵抗がある方もご安心下さい。本サービスでは端末の位置情報を送信したり共有するような機能はございません。また募集をかけない限り他のユーザーに検索される心配もございません。
                    </p>
                </v-col>
            </v-row>
            -->
            <h4 style="color: grey; margin-bottom: 1rem; padding-top: 2rem;">位置情報を使って。</h4>
            <v-row align="center" justify="center" style="margin-bottom: 1rem;">
                <v-col cols="3">
                    <v-img src="@/assets/sample_mock3.jpg"></v-img>
                </v-col>
                <v-col cols="8">
                    <p style="font-size: 0.6rem; color: grey;">
                        本サービスでは位置情報を使って半径10km圏内でユーザーを探す機能をご用意しました。急に予定が空いた時、暇で仕方ない時など、どんな時でも24時間ご利用頂けます。
                    </p>
                    <p style="font-size: 0.6rem; color: grey;">
                        位置情報を使うことに抵抗がある方もご安心下さい。本サービスでは端末の位置情報を送信したり共有するような機能はございません。また募集をかけない限り他のユーザーに検索される心配もございません。
                    </p>
                </v-col>
            </v-row>

            <!--紹介4
            <h4 style="color: grey; margin-bottom: 1rem; padding-top: 0;">女性は完全無料。男性も業界最安水準の料金で。</h4>
            <p style="font-size: 0.6rem; color: grey;">
                マッチングアプリは男性の利用料が高いというお声から、本サービスでは業界最安水準の利用料を意識しております。 1ヶ月単位での定額利用の他に、1日限定でお試し利用頂けるプランもご用意しました。
            </p>
            <p style="font-size: 0.6rem; color: grey;">
                女性の方は完全無料でお使い頂けます。追加料金が発生する事も一切ありませんのでご安心下さい。
            </p>
            -->

            <!--紹介5
            <h4 style="color: grey; margin-bottom: 1rem; padding-top: 2rem;">現実(リアル)特化のマッチングアプリを目指して。</h4>
            <p style="font-size: 0.6rem; color: grey;">
                スケジュールの決まったデート、合コン。位置情報を利用したリアルタイムの出会いの他に、本サービスでは街コンの募集を予定しております。これ1つでどんな出会いもすぐに叶う。そのようなサービスを目指して参ります。
            </p>
            -->
            <br><br><br><br>
        </v-container>

        <!--PC版サービス紹介-->
        <v-container v-else class="matching_pc" style="padding-top: 3rem;">
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="10" lg="10" xl="8" class="pa-md-4 mx-lg-auto" style="padding-left: 0; padding-right: 0; padding-top: 0;">
                    <!--紹介1
                    <h3 style="color: grey;">必要なのは時間、場所、やりたい事だけ。</h3>
                    <p style="font-size: 0.8rem; color: grey;">
                        マッチングアプリ疲れが叫ばれる昨今、マッチした後のやり取りが続かなかったり、いつまで経っても実際に会えないと悩んでいませんか。
                        本サービスではいつ、どこで、何をしたいかを決めた上でマッチングします。
                    </p>
                    <p style="font-size: 0.8rem; color: grey;">
                        マッチング後はメッセージを送って細かい調整をするだけだから簡単、そしてタイムパフォーマンスに優れています。美味しいご飯を食べに行ったり、一緒にスポーツ観戦したり、自分が最も理想とする出会いの形をセッティング頂けます。
                    </p>
                    -->

                    <!--紹介1-->
                    <h3 style="color: grey;">必要なのは時間、場所、やりたい事だけ。</h3>
                    <p style="font-size: 0.8rem; color: grey;">
                        レッツミーは様々な方法で新しい繋がりを生み出すサービスです。時間・場所・目的を共有するからお互いが打ち解けるまでとても早く、一生ものの友達ができることでしょう。
                    </p>

                    <!--紹介2
                    <h3 style="margin-top: 3rem; color: grey;">ひとりでも友達と一緒でも。最大16人で合コン。</h3>
                    <p style="font-size: 0.8rem; color: grey;">
                        本サービスの最大の特徴。それは何と言っても複数人でのマッチング機能です。おひとり様同士のマッチングは勿論、幹事が中心となってグループ同士でマッチングする事が可能です。
                    </p>
                    <p style="font-size: 0.8rem; color: grey;">
                        友達と一緒だから安心安全。友達と一緒だから盛り上がる。あなたも身近な人を誘って素敵な出会いを楽しみませんか？
                    </p>
                    -->
                    <!--紹介2-->
                    <h3 style="margin-top: 3rem; color: grey;">ひとりでも友達と一緒でも。最大16人で。</h3>
                    <p style="font-size: 0.8rem; color: grey;">
                        本サービスの最大の特徴。それは何と言っても複数人で利用できる点です。おひとり様同士は勿論、幹事が中心となってグループ同士で合流する事が可能です。
                    </p>

                    <!--紹介3
                    <h3 style="margin-top: 3rem; color: grey;">位置情報を使って今すぐ出会える。</h3>
                    <p style="font-size: 0.8rem; color: grey;">
                        前もって予定を立てる通常のマッチング機能の他に、本サービスでは位置情報を使って半径10km圏内で今すぐ出会えるお相手を探す機能をご用意しました。急に予定が空いた時、暇で仕方ない時など、どんな時でも24時間お相手をお探し頂けます。
                    </p>
                    <p style="font-size: 0.8rem; color: grey;">
                        位置情報を使うことに抵抗がある方もご安心下さい。本サービスでは端末の位置情報を送信したり共有するような機能はございません。また募集をかけない限り他のユーザーに検索される心配もございません。
                    </p>
                    -->
                    <!--紹介3-->
                    <h3 style="margin-top: 3rem; color: grey;">位置情報を使って。</h3>
                    <p style="font-size: 0.8rem; color: grey;">
                        本サービスでは位置情報を使って半径10km圏内でユーザーを探す機能をご用意しました。急に予定が空いた時、暇で仕方ない時など、どんな時でも24時間お相手をお探し頂けます。
                    </p>
                    <p style="font-size: 0.8rem; color: grey;">
                        位置情報を使うことに抵抗がある方もご安心下さい。本サービスでは端末の位置情報を送信したり共有するような機能はございません。また募集をかけない限り他のユーザーに検索される心配もございません。
                    </p>

                    <!--紹介4
                    <h3 style="margin-top: 3rem; color: grey;">女性は完全無料。男性も業界最安水準の料金をご用意。</h3>
                    <p style="font-size: 0.8rem; color: grey;">
                        マッチングアプリは男性の利用料が高いというお声から、本サービスでは業界最安水準の利用料を意識しております。 1ヶ月単位での定額利用の他に、1日限定でお試し利用頂けるプランもご用意しました。
                    </p>
                    <p style="font-size: 0.8rem; color: grey;">
                        女性の方は完全無料でお使い頂けます。追加料金が発生する事も一切ありませんのでご安心下さい。
                    </p>
                    -->

                    <!--紹介5
                    <h3 style="margin-top: 3rem; color: grey;">現実(リアル)特化のマッチングアプリを目指して。</h3>
                    <p style="font-size: 0.8rem; color: grey;">
                        スケジュールの決まったデート、合コン。位置情報を利用したリアルタイムの出会いの他に、本サービスでは街コンの募集を予定しております。これ1つでどんな出会いもすぐに叶う。そのようなサービスを目指して参ります。
                    </p>
                    -->
                    <br><br><br><br>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'Matching',
        components: {
        },
    }
</script>