import { render, staticRenderFns } from "./HeaderPc.vue?vue&type=template&id=c3b8eab4&scoped=true&"
var script = {}
import style0 from "./HeaderPc.vue?vue&type=style&index=0&id=c3b8eab4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3b8eab4",
  null
  
)

export default component.exports